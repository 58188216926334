export const MOCKED_LINKEDIN_USER_DATA = {
  city: 'Seattle',
  country: 'US',
  country_full_name: 'United States',
  education: [
    {
      grade: 'Graduate with distinction',
      school: 'Oxford',
      ends_at: {
        day: 31,
        year: 2018,
        month: 1,
      },
      logo_url:
        'https://cdn.collective.work/profiles-assets/cloelscwe04g893c24wnyhzft-logo.jpg',
      starts_at: {
        day: 1,
        year: 2014,
        month: 10,
      },
      degree_name: 'Bachelor',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer quis ultrices diam. Nam volutpat lorem tellus, a venenatis leo finibus non. Sed ut bibendum magna. Fusce iaculis, mi eget egestas euismod, diam tellus consequat tellus, ac tincidunt nibh nisl vel massa. Etiam ac justo ex. Aenean tempus leo nec leo imperdiet pellentesque.',
      field_of_study: 'Computer Science',
      activities_and_societies:
        'Sed nulla ante, egestas et elementum quis, pellentesque sed leo. Mauris vel tempus urna. Morbi eu dignissim turpis, sed consectetur lorem. Phasellus sed pretium ex. Donec risus tortor, ullamcorper non placerat et, faucibus ut enim. Nam commodo lorem eu imperdiet consectetur.',
      school_linkedin_profile_url: null,
    },
    {
      grade: 'Graduate',
      school: 'Oxford',
      ends_at: {
        day: 30,
        year: 2019,
        month: 10,
      },
      logo_url:
        'https://cdn.collective.work/profiles-assets/cloelscwe04g893c24wnyhzft-logo.jpg',
      starts_at: {
        day: 1,
        year: 2018,
        month: 1,
      },
      degree_name: 'Master of Science',
      description:
        'Suspendisse pellentesque dolor vitae enim porttitor venenatis. Praesent dignissim lorem quis elit consectetur, nec egestas justo dignissim. Sed a euismod orci, sit amet interdum nisl. Cras in laoreet quam. Etiam orci massa, finibus convallis dapibus et, elementum non nisl. Fusce bibendum molestie elit, ac placerat mauris aliquet a. Donec et odio mi. Vivamus tempor, tellus a fermentum blandit, nisi elit viverra ligula, non elementum augue dolor sit amet ipsum.',
      field_of_study: 'Computer Science',
      activities_and_societies: null,
      school_linkedin_profile_url: null,
    },
  ],
  experiences: [
    {
      company: 'YouTube',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/youtube',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse posuere vulputate dolor, sit amet rhoncus tortor varius eget. Duis ut magna nisl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      ends_at: null,
      location: 'San Bruno, California',
      logo_url: 'https://cdn.collective.work/clo6sua1c000108mg5lp23as8.jpeg',
      starts_at: {
        day: 1,
        month: 1,
        year: 2023,
      },
      title: 'CTO',
    },
    {
      company: 'YouTube',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/youtube',
      description:
        'Aenean maximus ut ipsum vitae interdum. Fusce ullamcorper purus eget urna iaculis, vel hendrerit sapien tempus. Aliquam efficitur sit amet leo sit amet venenatis. Pellentesque turpis sapien, mattis eleifend massa ut, posuere consequat orci.',
      ends_at: {
        day: 31,
        month: 1,
        year: 2022,
      },
      location: 'San Bruno, California',
      logo_url: 'https://cdn.collective.work/clo6sua1c000108mg5lp23as8.jpeg',
      starts_at: {
        day: 1,
        month: 1,
        year: 2019,
      },
      title: 'Senior Full-Stack Developer',
    },
    {
      company: 'YouTube',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/youtube',
      description:
        'Nulla in aliquet lectus, eu semper diam. Phasellus rutrum placerat dolor eget suscipit. Nam porttitor tincidunt dapibus. Vestibulum volutpat nunc magna, id semper neque malesuada ut.',
      ends_at: {
        day: 31,
        month: 1,
        year: 2019,
      },
      location: 'San Bruno, California',
      logo_url: 'https://cdn.collective.work/clo6sua1c000108mg5lp23as8.jpeg',
      starts_at: {
        day: 1,
        month: 1,
        year: 2017,
      },
      title: 'Full-Stack Developer',
    },
    {
      company: 'Amazon',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/amazon',
      description:
        'Nunc sed elit urna. Pellentesque ac semper orci. Nam elementum vitae odio at feugiat. Nulla quis viverra massa. Pellentesque ac euismod nisl. Vestibulum lobortis, est eget accumsan pretium, ligula neque rutrum quam, et accumsan sapien sapien et magna. Praesent tincidunt eros efficitur, porta leo id, condimentum quam. Integer eu libero ex.',
      ends_at: {
        day: 1,
        month: 1,
        year: 2024,
      },
      location: 'Seattle, Washington',
      logo_url: 'https://cdn.collective.work/clo6su3cu000008mgevgugwy9.jpeg',
      starts_at: {
        day: 1,
        month: 1,
        year: 2023,
      },
      title: 'Senior Product Manager',
    },
  ],
  first_name: 'John',
  full_name: 'John Doe',
  headline: 'Financial Freedom through Real Estate - LinkedIn Top Voice',
  languages: ['English', 'Spanish', 'Japanese'],
  last_name: 'Doe',
  profile_pic_url: 'https://new-avatar-url',
  occupation: 'CEO',
  extra: null,
  state: null,
  gender: null,
  groups: [],
  skills: [
    'Software Engineering',
    'Mathematics',
    'Java',
    'Website Development',
    'Android Development',
    'Ruby on Rails',
    'Ruby',
    'C',
    'C++',
    'HTML',
    'CSS',
    'AngularJS',
    'Bootstrap',
    'Haskell',
    'Prolog',
    'JavaScript',
    'jQuery',
    'Git',
    'Google Analytics',
    'SEO',
  ],
  summary:
    '🇫🇷 French software engineer now entrepreneur & CTO, currently revolutionising the freelance space @Collective\n\n🏋️ CrossFit and sport addict, eternal optimist\n\n3️⃣0️⃣ Forbes 30 under 30 Europe\nhttps://www.forbes.com/profile/collective/?list=30under30-europe-social-impact\n\n🚀 Collective is the "LinkedIn for freelancers", where recruiters/clients can connect to find the best freelancers, commission-free (0%)\n\nTry it out now ➡️ https://www.collective.work/recruiters\n\nWe are driven by offering the best freelancer experience on the market. This is why we unlock new capabilities for freelancers: they can connect, meet, get work thanks to recruiters contacting them, invoice and do quotes via our unique tools (also made collaborative for teams of freelancers).\n\n4️⃣ Some numbers we achieved (May 2024):\n- Raise a mammoth seed of 8M$ to change the world of freelancing\n- 30,000+ freelancers on the platform, connecting regularly\n- Built the first commission-free (0%) freelancer search out there - https://app.collective.work/recruiter/search\n\nOur stack:\nFrontend: Typescript, React, GraphQL\nBackend: Typescript, NestJS, GraphQL, PostgreSQL\nInfra: Helm, Kubernetes, AWS\nTools: Github, Github Actions for CI, Forest Admin, Datadog\n\n💬 If you want to have a chat, just drop me a note at paul@collective.work. \n\nAnd feel free to connect, I\'m nice I promise 😉',
  articles: [],
  industry: null,
  interests: [],
  activities: [],
  birth_date: null,
  connections: null,
  certifications: [
    {
      url: 'https://aws.amazon.com/certification/',
      name: 'AWS Developer Associate',
      ends_at: null,
      authority: 'Amazon Web Services (AWS)',
      starts_at: {
        day: 1,
        year: 2018,
        month: 11,
      },
      display_source: 'amazon.com',
      license_number: 'PXJPJM712NFEQZ5V',
    },
    {
      url: 'https://aws.amazon.com/certification/',
      name: 'AWS Solution Architect Associate',
      ends_at: null,
      authority: 'Amazon Web Services (AWS)',
      starts_at: {
        day: 1,
        year: 2018,
        month: 11,
      },
      display_source: 'amazon.com',
      license_number: 'EVHSNSYCCE4E1JW0',
    },
  ],
  follower_count: 8547,
  volunteer_work: [
    {
      cause: 'ENVIRONMENT',
      title: 'Fire prevention',
      company: 'Ville de Marseille',
      ends_at: {
        day: 31,
        year: 2012,
        month: 7,
      },
      logo_url:
        'https://cdn.collective.work/profiles-assets/clo5hwahv0cge6vcicd8yctyj-logo.jpg',
      starts_at: {
        day: 1,
        year: 2012,
        month: 7,
      },
      description:
        'Took part in two weeks of fire prevention. We were responsible for surveilling a region around Marseille, alerting and helping the firefighters in the event of a fire.',
      company_linkedin_profile_url:
        'https://www.linkedin.com/company/ville-de-marseille/',
    },
    {
      cause: 'CHILDREN',
      title: 'Scout Leader',
      company: 'Scouts et Guides de France',
      ends_at: {
        day: 31,
        year: 2016,
        month: 8,
      },
      logo_url:
        'https://cdn.collective.work/profiles-assets/clo5hwahy0cgf6vci1gbyfem4-logo.jpg',
      starts_at: {
        day: 1,
        year: 2013,
        month: 9,
      },
      description:
        'After being a scout for 6 years, I decided to become myself a scout leader. I coordinate with other leaders a troop of 30 young boys aged between 12 and 17 based in London.',
      company_linkedin_profile_url:
        'https://www.linkedin.com/company/scouts-et-guides-de-france/',
    },
    {
      cause: 'CHILDREN',
      title: 'Scout Leader',
      company: 'Scouts Unitaires de France',
      ends_at: {
        day: 31,
        year: 2019,
        month: 8,
      },
      logo_url:
        'https://cdn.collective.work/profiles-assets/clo5hwai00cgg6vci59ui79q3-logo.jpg',
      starts_at: {
        day: 1,
        year: 2017,
        month: 9,
      },
      description:
        'Scout leader in the new and first troop of Scout Unitaire de France based in London. Managing 33 young boys aged between 12 and 17 years old.',
      company_linkedin_profile_url:
        'https://www.linkedin.com/company/scouts-unitaires-de-france/',
    },
  ],
  inferred_salary: null,
  personal_emails: [],
  recommendations: [],
  personal_numbers: [],
  public_identifier: 'paulvidal96',
  people_also_viewed: [
    {
      link: 'https://www.linkedin.com/in/richardartoul',
      name: 'Richard Artoul',
      summary: 'Co-Founder @ WarpStream Labs',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/cyrilcodron',
      name: 'Cyril Codron',
      summary: 'Head of Product @Collective.work',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/obonnet',
      name: 'Olivier Bonnet',
      summary: 'CTO @ BlaBlaCar, Angel Investor, formerly iCloud @ Apple',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/louisblin',
      name: 'Louis Blin',
      summary: 'Software Engineer at PDT Partners',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/vascoalexandre',
      name: 'Vasco Alexandre',
      summary:
        'Co-founder & CEO at Dotfile - Automating Business Verification (KYB)',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/brice-lepoutre',
      name: 'Brice Lepoutre',
      summary: 'Analytics Engineer @ Datadog',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/ruben-gueunoun-a218b7108',
      name: 'Ruben Gueunoun',
      summary: 'Co-founder & CRO @Crew',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/angelique-chaboche-56bb3a5a',
      name: 'Angelique Chaboche',
      summary:
        'Head of Business Development @ Collective | Ex-McKinsey | Hiring',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/emandon',
      name: 'Édouard Mandon',
      summary:
        'Co-founder & CEO at Numeral, bank orchestration platform for fintechs',
      location: null,
    },
    {
      link: 'https://www.linkedin.com/in/théo-bartholmé-40a5978a',
      name: 'Théo Bartholmé',
      summary: 'Finance Lead @Collective.work',
      location: null,
    },
  ],
  accomplishment_courses: [],
  accomplishment_patents: [],
  accomplishment_projects: [
    {
      url: null,
      title: 'Pintos Operating System (2nd Year Group Project)',
      ends_at: {
        day: 31,
        year: 2016,
        month: 1,
      },
      starts_at: {
        day: 1,
        year: 2016,
        month: 1,
      },
      description:
        'Designed an operating system in C named Pintos, based on a Stanford project to enhance the understanding of OS. Implemented through 4 tasks a clock, a scheduler, ability to run user programs and virtual memory for user programs.',
    },
    {
      url: null,
      title:
        'ARM assembler and emulator with morse autocomplete  (1st Year Group Project)',
      ends_at: {
        day: 30,
        year: 2015,
        month: 6,
      },
      starts_at: {
        day: 1,
        year: 2015,
        month: 6,
      },
      description:
        "Wrote an ARM11 emulator and assembler in C. As an extension, we chose to implement a morse autocomplete where we would type inputs using a raspberry Pi buttons' in morse. We would then display on a screen the inputs and perform a word autocompletion.",
    },
    {
      url: null,
      title: 'Personal Statement Examples - PSE',
      ends_at: null,
      starts_at: {
        day: 1,
        year: 2014,
        month: 9,
      },
      description:
        'Designed a website from scratch with two partners to help students go through their UCAS process (english universities applications) by collecting 120 personal statements (motivational letters sent to universities) from connections and displaying them online with a concise method. \n\nThe website got 12 000 unique visits in the first year following its launch, with over 50 000 page views. It now receives more than 200 unique visits per day.\n\nTechnology used: Ruby on Rails, HTML, CSS, Javascript\nDeveloped skills: SEO, website analysis using Google Analytics',
    },
    {
      url: null,
      title: 'London Housing Price Prediction (3rd Year Group project)',
      ends_at: {
        day: 31,
        year: 2016,
        month: 12,
      },
      starts_at: {
        day: 1,
        year: 2016,
        month: 10,
      },
      description:
        'Machine learning project\n\nUsing agile methods, we are currently creating a machine learning algorithm in a team of 6 to predict the future price of houses in London. Results will be displayed both on an Android and IOS application.',
    },
    {
      url: null,
      title: 'UniSport (2nd Year Group Project)',
      ends_at: {
        day: 30,
        year: 2016,
        month: 6,
      },
      starts_at: {
        day: 1,
        year: 2016,
        month: 5,
      },
      description:
        'Spent one month creating a full product in a team of 4. We had to discuss our ideas every week and share our progress following an agile style of working.\n\nOur project was to connect university students for finding sport partners. \n\nThe flow was as follow:\n\n1) People would submit requests available online where they would tell that they will attend a sport session and needed a partner. \n2) Other people would be able to see this request and ask to join the sport session.\n3) The event creator would then choose with who he would like to do the session.\n4) The phone numbers of the event creator and of the participant where then revealed to each other so they could get in contact.\n\nTechnology used: Ruby on Rails, Devise for authentification, AngularJS, HTML, CSS, Bootstrap, Heroku \n\nTo test as a logged user, input the following in the login page\nlogin: pv14@ic.ac.uk\npassword: 123456',
    },
    {
      url: null,
      title: 'Selfie Hunter',
      ends_at: {
        day: 31,
        year: 2016,
        month: 1,
      },
      starts_at: {
        day: 1,
        year: 2015,
        month: 6,
      },
      description:
        'Created with a partner a social android application where the idea is to steal funny selfies from your friends.\n\nWas responsible of designing the front end of the application using java, android development tools (Gradle, Crashlitycs for crash reports) and various android libraries and frameworks (Picasso, testing frameworks Mockito and JUnit, UI testing framework Expresso).',
    },
    {
      url: null,
      title: 'WACC Compiler (2nd Year Group Project)',
      ends_at: {
        day: 31,
        year: 2015,
        month: 12,
      },
      starts_at: {
        day: 1,
        year: 2015,
        month: 10,
      },
      description:
        'Implemented a compiler using Java and ANTLR languages for the academic programming language WACC, analysing the syntax and semantic before producing assembly code for the ARM11 architecture.',
    },
    {
      url: null,
      title:
        'Computer Algebra Project - Maps, Sets and Fractals (1st Year Group Project)',
      ends_at: {
        day: 31,
        year: 2015,
        month: 3,
      },
      starts_at: {
        day: 1,
        year: 2014,
        month: 12,
      },
      description:
        'Studied the properties of non-linear real maps such as the logistic map, the Verhulst process, and a trigonometric map both numerically and mathematically. Also investigated sets and fractals such as the Mandelbrot set and Julia sets.\n',
    },
    {
      url: null,
      title: 'The impact of video games on the brain (Year 12)',
      ends_at: {
        day: 31,
        year: 2013,
        month: 3,
      },
      starts_at: {
        day: 1,
        year: 2012,
        month: 9,
      },
      description:
        'Was part of scientific group project lasting 7 months, focusing on the impact of video games on the brain. Found contacts in the neuroscience industry to support research report. Built the website to present the report.',
    },
  ],
  similarly_named_profiles: [
    {
      link: 'https://br.linkedin.com/in/vidalpaulr',
      name: 'Paul Vidal',
      summary:
        'Développeur Blockchain | Ethereum | Solidity | Node.js | TypeScript',
      location: 'São Paulo, Brésil',
    },
    {
      link: 'https://fr.linkedin.com/in/vidalpaul',
      name: 'Paul Vidal',
      summary: 'Account Executive @ Bigblue | ex The Kooples',
      location: 'Paris',
    },
    {
      link: 'https://www.linkedin.com/in/paul-vidal-63858129',
      name: 'Paul Vidal',
      summary: 'Owner, Specialized Physical Therapy, LLC',
      location: 'Cherry Hill, NJ',
    },
    {
      link: 'https://fr.linkedin.com/in/paul-vidal-',
      name: 'Paul Vidal',
      summary: 'Channel Account Manager, grand sud chez Sophos France',
      location: 'Versailles',
    },
  ],
  accomplishment_test_scores: [],
  background_cover_image_url: null,
  accomplishment_publications: [],
  accomplishment_honors_awards: [
    {
      title:
        'Kangourou des Mathematiques - Mathematical and Logic National Contest',
      issuer: 'Mathématiques sans frontières',
      issued_on: {
        day: 1,
        year: 2011,
        month: 2,
      },
      description: 'Scored 38th of nearly 20,000 candidates of my age',
    },
  ],
  accomplishment_organisations: [],
};
