import {
  Box,
  Collapse,
  Divider,
  Flex,
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconFourPointedStar,
  List,
  ListIcon,
  ListItem,
  Tag,
  Text,
  useBoolean,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { SimilarityEvaluation } from './types';

type SoloApplicationSimilarityEvaluationProps = {
  evaluation: SimilarityEvaluation;
};

export const SoloApplicationSimilarityEvaluation = ({
  evaluation,
}: SoloApplicationSimilarityEvaluationProps) => {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useBoolean(false);

  const { overallScore, reasons } = evaluation;

  const similarityThreshold = 75;

  const categoryLabel =
    overallScore >= similarityThreshold
      ? t('recruiter.profileLookalike.similarityScore.verySimilarNoScore')
      : t('recruiter.profileLookalike.similarityScore.similarNoScore');

  const color =
    overallScore > similarityThreshold ? 'success.700' : 'decorative.sky.500';

  const bg =
    overallScore > similarityThreshold ? 'success.200' : 'decorative.sky.100';

  return (
    <Box
      p="20px"
      background="primary.1"
      border="1px solid var(--chakra-colors-primary-500)"
      borderRadius="12px"
    >
      <Flex align="center">
        <Flex align="center" gap="8px">
          <IconFourPointedStar color="primary.600" size="xs" />
          <Text variant="desktop-m-medium" color="primary.800">
            {t('recruiter.profileLookalike.similarityScore.title', {
              overallScore,
            })}
          </Text>
          <Tag size="md" color={color} bg={bg} label={categoryLabel} />
        </Flex>
        <Box
          onClick={setCollapsed.toggle}
          ml="auto"
          mr={0}
          cursor="pointer"
          color="rythm.600"
          sx={{ 'svg path': { strokeWidth: '1px' } }}
        >
          {isCollapsed ? <IconChevronDown /> : <IconChevronUp />}
        </Box>
      </Flex>
      <Collapse in={!isCollapsed} speed="fast">
        <Divider my="20px" />
        <List color="rythm.900" spacing="8px">
          {reasons.map((reason) => (
            <ListItem key={reason}>
              <Text variant="desktop-m-regular">
                <ListIcon as={IconCheck} w={4} h={4} color={color} />
                {reason}
              </Text>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};
